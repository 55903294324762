<template>
	<div class="fill-height white">
		<AppBar :to-page="back" dark :color="config.color" />
		<v-app-bar dark dense :color="config.color" flat>
			<v-app-bar-title
				class="flex text-h5 text-center font-weight-black"
				>{{ t("confirmation.header.title") }}</v-app-bar-title
			>
		</v-app-bar>
		<v-app-bar
			prominent
			dark
			dense
			:color="config.color"
			flat
			v-if="!closedMode"
		>
			<v-card-text
				class="subtitle-2 flex text-center"
				v-html="t('confirmation.header.subtitle')"
			></v-card-text>
		</v-app-bar>
		<v-card elevation="4">
			<v-container>
				<v-row>
					<v-col cols="12">
						<LayoutBase
							:title="t('pickupDropOff.reservationNumber')"
						>
							<CardReservationNumber
								:service="checkinModel"
								confirm-mode
							/>
						</LayoutBase>
					</v-col>
					<v-col cols="12">
						<LayoutBase
							dark
							:title="t('pickupDropOff.title')"
							:disabledEdit="closedMode"
							edit="/reservation"
						>
							<LayoutCard
								icon="hail"
								:title="t('pickupDropOff.form.pickup.location')"
                @click="routeTo('/reservation')"
								>{{ pickupfullName }}</LayoutCard
							>
							<LayoutCard
								class="mt-3"
								icon="location_on"
								:title="
									t('pickupDropOff.form.dropOff.location')
								"
                @click="routeTo('/reservation')"
								>{{ dropofffullName }}</LayoutCard
							>
						</LayoutBase>
					</v-col>
					<v-col cols="12" v-if="existAirport">
						<LayoutBase
							:title="t('flight.title')"
							:disabledEdit="closedMode"
							edit="/reservation/flight"
						>
              <LayoutFlight
                @click="routeTo('/reservation/flight')"

                :flightNumber="`${checkinModel.flightNumber} ${airline}`"
                :flightTime="checkinModel.flightDatetime"
              />
						</LayoutBase>
					</v-col>
					<v-col cols="12">
						<LayoutBase
							:title="t('schedule.title')"
							:disabledEdit="closedMode"
							edit="/reservation/pschedule"
						>
							<LayoutDateTime
								:pickupTime="checkinModel.pickupDatetime"
                :pickupTimeLimit="checkinModel.pickupDatetimeLimit"
								:dateTitle="t('schedule.pickupDate')"
								:timeTitle="t('schedule.pickupTime')"
                @click="routeTo('/reservation/pschedule')"
							/>
						</LayoutBase>
					</v-col>
					<v-col cols="12">
						<LayoutBase
							:title="t('passenger.titleConfirm')"
							:disabledEdit="closedMode"
							edit="/reservation/passenger"
						>
							<CardTransferType @click="routeTo('/reservation/passenger')" confirm-mode />
						</LayoutBase>
					</v-col>
					<v-col cols="12">
						<LayoutBase
							:title="t('confirmation.form.specialRequest')"
						>
							<v-card
								elevation="4"
								width="100%"
								class="rounded-lg"
							>
								<v-textarea
									height="100%"
									hide-details=""
									v-model="clientNote"
									dense
									:disabled="disabledNode"
									outlined
									:placeholder="placeHolderNote"
								></v-textarea>
							</v-card>
						</LayoutBase>
						<div
							class="mx-3 mt-1 caption font-weight-thin"
							v-if="!disabledNode"
						>
							{{ t("confirmation.form.specialRequestMessage") }}
						</div>
						<div
							class="mx-3 mt-1 caption font-weight-thin red--text"
							v-else
						>
							{{ $t("info.locked.aftertime") }}
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
		<v-card class="mt-4" elevation="4" v-if="!closedMode">
			<v-container>
				<v-card-actions class=" justify-center">
					<v-checkbox
						v-model="acceptTerms"
						:key="check"
						@click="openTerms"
					>
					</v-checkbox>
					<div class="body-2 black--text font-weight-black">
						<span>
							I accept
						</span>
						<a
							@click="dialog.termAndConditions = true"
							class="text-decoration-underline black--text"
						>
							terms and conditions</a
						>
					</div>
				</v-card-actions>
				<v-row dense class="justify-center my-2">
					<!--    <v-col cols="8" v-if="isBeforeTime"
            ><v-btn color="primary" block rounded outlined @click="save()">{{
              $t("global.btnSave")
            }}</v-btn></v-col
          > -->
					<v-col cols="8"
						><v-btn
							color="primary"
							:disabled="!acceptTerms"
							block
							rounded
							@click="dialog.confirmation = true"
							>{{ $t("global.btnSaveAndSend") }}</v-btn
						></v-col
					>
				</v-row>
			</v-container>
		</v-card>
		<DialogMessages
			v-model="dialog.confirmation"
			type="confirm"
			:title="info.title"
		>
			{{ info.message }}
			<template v-slot:bottom>
				<v-card-actions>
					<v-btn
						rounded
						color="primary"
						small
						class="font-weight-bold"
						@click="send()"
						><div class="px-2">
							{{ $t("global.btnConfirmSend") }}
						</div></v-btn
					>
				</v-card-actions>
			</template>
		</DialogMessages>
		<DialogTermAndCond
			v-model="dialog.termAndConditions"
			@accept="acceptTerms = true"
		/>
	</div>
</template>

<script>
import AppBar from "@/components/AppBar";
import CardReservationNumber from "@/components/card/CardReservationNumber";
import CardTransferType from "@/components/card/CardTransferType";
import DialogMessages from "@/components/dialog/DialogMessages";
import LayoutBase from "@/layouts/LayoutBase";
import LayoutCard from "@/layouts/LayoutCard";
import LayoutDateTime from "@/layouts/LayoutDateTime";
import LayoutFlight from "@/layouts/LayoutFlight";
import DialogTermAndCond from "@/components/dialog/DialogTermsAndCond";
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";
import { mapFields } from "@/helpers/mapFields";
export default {
	components: {
		AppBar,
		LayoutBase,
		LayoutCard,
		LayoutDateTime,
    LayoutFlight,
		DialogTermAndCond,
		CardTransferType,
		CardReservationNumber,
		DialogMessages
	},
	data: () => ({
		config: {
			color: "primary"
		},
		dialog: {
			confirmation: false,
			termAndConditions: false
		},
		acceptTerms: false,
		check: 0
	}),
	computed: {
		...mapGetters("service", [
			"isArrival",
			"getBasePath",
			"existAirport",
			"inputDisable",
			"inputDisableType",
			"closedMode"
		]),
		...mapState("service", ["checkinModel"]),
		...mapFields({
			fields: ["clientNote"],
			base: "checkinModel",
			mutation: "SET_UPDATE_MODEL",
			module: "service"
		}),

		pickupfullName: vm => vm.checkinModel.pickup?.fullName || "",
		dropofffullName: vm => vm.checkinModel.dropoff?.fullName || "",
		airline: vm => vm.checkinModel.flightValidationData?.airline || "",
		disabledNode: vm => vm.inputDisableType === "aftertime",
		placeHolderNote: vm =>
			vm.disabledNode
				? ""
				: vm.t("confirmation.form.specialRequestPlaceholder"),
		flightDateTitle() {
			return this.isArrival
				? "flight.form.arrivalDate"
				: "flight.form.takeoffDate";
		},
		flightTimeTitle() {
			return this.isArrival
				? "flight.form.arrivalTime"
				: "flight.form.takeoffTime";
		},
		back() {
			return this.closedMode
				? this.getBasePath("/closed")
				: this.getBasePath("/reservation/passenger");
		},
		info: vm => ({
			title: vm.$t(`global.message.confirm.title`),
			message: vm.$t(`global.message.confirm.message`)
		})
	},
	methods: {
		...mapActions("service", ["_updateService", "_fetchServiceById"]),
    ...mapMutations("service", ["RESET_STATE"]),
		openTerms() {
			if (this.acceptTerms) {
				this.dialog.termAndConditions = true;
				this.acceptTerms = undefined;
				setTimeout(() => this.check++, 100);
			}
		},
		async save() {
			this._updateService({
				clientNote: this.clientNote
			}).then(() => {

				this.$root.show({
					type: "success",
					title: this.$t("global.message.savedTitle"),
					description: this.$t("global.message.saved")
				});
				if (this.$route.query.utm_source == "travelers") {
					this.toTravelers();
				}
			});
		},
		toTravelers() {
			this.$router.push({
				path: "/",
				query: { token: this.$route.query.token }
			});
		},
    routeTo(path){
      if (!this.closedMode) this.$router.push(this.getBasePath(path));
    },
		async send() {
			this.dialog.confirmation = false;
			this._updateService({
				clientNote: this.clientNote,
				checkinStatus: "acepted"
			}).then(async () => {
				await this._fetchServiceById();
				this.$root.show({
					type: "success",
					title: this.$t("global.message.success.title"),
					description: this.$t("global.message.success.message")
				});
				if (this.$route.query.utm_source == "travelers") {
          this.RESET_STATE()
					this.toTravelers();
				} else {
					this.$router.push(this.getBasePath("/form/contact"));
				}
			});
		},
		t(key, options = {}) {
			return this.$t(`section.${key}`, options);
		}
	}
};
</script>
